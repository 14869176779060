// external imports
import React, { useState, useContext, useEffect } from "react";
import * as BsIcons from "react-icons/bs";

// component imports
import FormInput from "../../Components/Generics/FormInput/FormInput";
import FormButton from "../../Components/Generics/FormButton/FormButton";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
// import "./LoginForm.css";

function ResetPasswordConfirmForm(props) {
  const { theme } = useContext(ThemeContext);

  const [values, setValues] = useState({
    uid: "",
    token: "",
    new_password: "",
    re_new_password: "",
  });

  useEffect(() => {
    let parameters = window.location.href.split("confirm/")[1];
    let activate_vals = parameters.split("/");
    setValues({
      uid: activate_vals[0],
      token: activate_vals[1],
      new_password: "",
      re_new_password: "",
    });
  }, []);

  const inputs = [
    {
      id: 5,
      name: "new_password",
      type: "password",
      placeholder: "new password",
      errorMessage:
        "Password should be 8-20 characters and should include atleast 1 letter and 1 number",
      label: "New Password",
      pattern: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,20}$",
      required: true,
    },
    {
      id: 6,
      name: "re_new_password",
      type: "password",
      placeholder: "confirm new password",
      errorMessage: "Passwords dont match",
      label: "Confirm new password",
      pattern: values.password,
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.resetPasswordConfirm(values);
  };

  return (
    <form onSubmit={handleSubmit} className={`form ${theme}`}>
      <div className="login-icon">
        <BsIcons.BsKeyFill color="F7A90D" size={130}></BsIcons.BsKeyFill>
      </div>
      {inputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
          lightbackground={"true"}
        />
      ))}
      <FormButton buttonText="Reset Password"></FormButton>
    </form>
  );
}

export default ResetPasswordConfirmForm;
