import React, { useContext, useState } from "react";
import * as BsIcons from "react-icons/bs";

// component imports
import FormButton from "../../Generics/FormButton/FormButton";
import FormInput from "../../Generics/FormInput/FormInput";
import { ThemeContext } from "../../Generics/ThemeContext/ThemeContext";

function RegisterUserForm(props) {
  const { theme } = useContext(ThemeContext);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    re_password: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "name",
      errorMessage:
        "name should be 3-16 characters and shouldn't include special characters.",
      pattern: "^[a-zA-Z0-9]{3,16}$",
      label: "Name",
      required: true,
    },
    {
      id: 4,
      name: "email",
      type: "email",
      placeholder: "email",
      errorMessage: "It should be a valid email address",
      label: "Email",
      required: true,
    },
    {
      id: 5,
      name: "password",
      type: "password",
      placeholder: "password",
      errorMessage:
        "Password should be 8-20 characters and should include atleast 1 letter and 1 number",
      label: "Password",
      pattern: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,20}$",
      required: true,
    },
    {
      id: 6,
      name: "re_password",
      type: "password",
      placeholder: "confirm password",
      errorMessage: "Passwords dont match",
      label: "Confirm password",
      pattern: values.password,
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createUser(values);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit} className={`form ${theme}`}>
      <div className="login-icon">
        <BsIcons.BsKeyFill color="F7A90D" size={130}></BsIcons.BsKeyFill>
      </div>
      {inputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
          lightbackground={"true"}
        />
      ))}
      <FormButton buttonText={"Submit"} />
    </form>
  );
}

export default RegisterUserForm;
