import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjangoService from "../../API/DjangoService";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
import SplashAnimation from "../../Components/LoginComponents/SplashAnimation/SplashAnimation";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPassword() {
  const { theme } = useContext(ThemeContext);
  const [resetSent, setResetSent] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (resetSent) {
      navigate("/");
    }
  }, [resetSent, navigate]);

  //login user
  const resetPassword = (values) => {
    DjangoService.ResetPassword(values).then((resp) => {
      if (200 < resp[1] < 299) {
        setResetSent(true);
      } else {
        // setLoginError({'show':true, 'msg':'A problem occurred while logging in, please try again later'})
      }
    });
  };

  return (
    <div className="overlay">
      <div className={`login-left-container ${theme}`}>
        <SplashAnimation></SplashAnimation>
      </div>
      <div className={`login-right-container ${theme}`}>
        <div className="login">
          <ResetPasswordForm resetPassword={resetPassword}></ResetPasswordForm>
          <div className="btn-div">
            <button
              className={`log-reg-btn ${theme}`}
              onClick={() => setResetSent(true)}
            >
              {"Back to Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
