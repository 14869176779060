export default class APIService {
  static localHostRoot = "http://127.0.0.1:8000/api/notes/";
  static aws_root = "https://notetrail.com/api/notes/";

  static current_root = this.aws_root;

  //login a user, get token
  static LoginUser(body) {
    return fetch(`${this.current_root}auth/jwt/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static VerifyJWT(body) {
    return fetch(`${this.current_root}auth/jwt/verify/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static CreateUser(body) {
    return fetch(`${this.current_root}auth/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static LoadUser() {
    return fetch(`${this.current_root}auth/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static ActivateUser(body) {
    return fetch(`${this.current_root}auth/users/activation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static ResetPassword(body) {
    return fetch(`${this.current_root}auth/users/reset_password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static ResetPasswordConfirm(body) {
    return fetch(`${this.current_root}auth/users/reset_password_confirm/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json().then((body) => [body, res.status]));
  }

  static GetNotes(token) {
    return fetch(`${this.current_root}note`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static PostNotes(body, token) {
    return fetch(`${this.current_root}note/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp);
  }

  static UpdateNotes(id, body, token) {
    return fetch(`${this.current_root}note/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp);
  }

  static DeleteNotes(note_id, token) {
    return fetch(`${this.current_root}note/${note_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  }
}
