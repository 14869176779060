// external imports
import React, { useState, useEffect, useRef } from "react";
import "./Toaster.css";

// component imports
import { toasterData } from "../../../Data/ToasterData";
import * as AiIcons from "react-icons/ai";

function Toaster(props) {
  const {
    type = "success",
    closeable = false,
    closeableClick,
    text = "",
    transition = false,
  } = props;
  const [status, setStatus] = useState([]);
  const [fadeCss, setFadeCss] = useState("");
  const hostRef = useRef("");

  // map prop status to correct notification type
  useEffect(() => {
    setStatus(
      toasterData.find((iconData) => {
        return iconData.type === type;
      })
    );
  }, [status]);

  return (
    <div className={`notification ${type}`}>
      <div>
        {closeable ? (
          <AiIcons.AiOutlineClose
            className="toast-button close-icon"
            color="red"
            onClick={() => closeableClick(false)}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="notification-icon">
        <span className="toast-span">{status.icon}</span>
      </div>
      <p className="notification-header toast-para">{status.title}</p>
      <p className="toast-para">{text}</p>
    </div>
  );
}

export default Toaster;
