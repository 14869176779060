import React, { useState } from "react";
import "./FormInput.css";

function FormInput(props) {
  const { label, errorMessage, onChange, id, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <div
      className={
        props.lightbackground === "true"
          ? "light-form-input"
          : "dark-form-input"
      }
    >
      <label className="input-label">{label}</label>
      <input
        {...inputProps}
        onChange={onChange}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        onBlur={handleFocus}
        focused={focused.toString()}
        className={"input"}
      />
      <span className="span-errors">{errorMessage}</span>
    </div>
  );
}

export default FormInput;
