import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TMFCarousel.css";
import Header from "../../Generics/Header/Header";
import TMFNote from "../TMFNote/TMFNote";

function TMFCarousel(props) {
  const { notes, editNote, deleteNote, formEditMode } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "grey" }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "grey" }}
        onClick={onClick}
      />
    );
  }

  return (
    <Slider
      {...settings}
      style={{
        borderRadius: "10px",
        boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
      }}
    >
      {" "}
      {notes.map((note, index) => (
        <li key={index}>
          <br />
          <Header text={note.Header}></Header>
          <br />
          <TMFNote
            note={note}
            editNote={editNote}
            deleteNote={deleteNote}
            formEditMode={formEditMode}
          ></TMFNote>
          <br />
        </li>
      ))}
    </Slider>
  );
}

export default TMFCarousel;
