import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjangoService from "../../API/DjangoService";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
import SplashAnimation from "../../Components/LoginComponents/SplashAnimation/SplashAnimation";
import ResetPasswordConfirmForm from "./ResetPasswordConfirmForm";

function ResetPasswordConfirm() {
  const { theme } = useContext(ThemeContext);
  const [resetConfirm, setResetConfirm] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (resetConfirm) {
      navigate("/");
    }
  }, [resetConfirm, navigate]);

  //login user
  const resetPasswordConfirm = (values) => {
    DjangoService.ResetPasswordConfirm(values).then((resp) => {
      if (200 < resp[1] < 299) {
        setResetConfirm(true);
      } else {
        // setLoginError({'show':true, 'msg':'A problem occurred while logging in, please try again later'})
      }
    });
  };

  return (
    <div className="overlay">
      <div className={`login-left-container ${theme}`}>
        <SplashAnimation></SplashAnimation>
      </div>
      <div className={`login-right-container ${theme}`}>
        <div className="login">
          <ResetPasswordConfirmForm
            resetPasswordConfirm={resetPasswordConfirm}
          ></ResetPasswordConfirmForm>
          <div className="btn-div">
            <button
              className={`log-reg-btn ${theme}`}
              onClick={() => setResetConfirm(true)}
            >
              {"Back to Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordConfirm;
