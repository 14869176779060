import React, { useState } from "react";
import "./TMFInput.css";

function TMFInput(props) {
  const { label, errorMessage, onChange, id, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <div className="form-input">
      <label className="input-label">{label}</label>
      <textarea
        {...inputProps}
        onChange={onChange}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        onBlur={handleFocus}
        focused={focused.toString()}
        className={"input"}
        rows="10"
        spellCheck="true"
      />
      <span className="span-errors">{errorMessage}</span>
    </div>
  );
}

export default TMFInput;
