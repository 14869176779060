// external imports
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// component imports
import APIService from "../../API/DjangoService";
import RegisterUserForm from "../../Components/LoginComponents/LoginForm/RegisterUserForm";
import LoginForm from "../../Components/LoginComponents/LoginForm/LoginForm";
import Toaster from "../../Components/Generics/Toaster/Toaster";
import "./LoginPage.css";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";

function LoginRegForm(props) {
  const { loginUser } = props;
  const { theme } = useContext(ThemeContext);
  let navigate = useNavigate();
  const [alreadyRegistered, setAlreadyRegistered] = useState(true);
  const [loginError, setLoginError] = useState({ show: false, msg: "" });
  const [registerError, setRegisterError] = useState({ show: false, msg: "" });

  //create user
  const createUser = (values) => {
    APIService.CreateUser(values).then((resp) => {
      if (200 < resp[1] < 299) {
        if (resp[0].id) {
          setAlreadyRegistered(true);
        } else {
          setAlreadyRegistered(true);
        }
      } else {
        setRegisterError({
          show: true,
          msg: "A problem occurred during registration, please try again later",
        });
      }
    });
  };

  const resetPassword = () => {
    navigate("/reset-password");
  };

  return (
    <>
      {alreadyRegistered ? (
        <>
          <LoginForm loginUser={loginUser} />
          {loginError.show && (
            <Toaster
              type={"error"}
              closeable={true}
              closeableClick={setLoginError}
              text={loginError.msg}
              transition={loginError.show}
            />
          )}
        </>
      ) : (
        <>
          <RegisterUserForm
            header={"Please Register"}
            createUser={createUser}
          />
          {registerError.show && (
            <Toaster
              type={"error"}
              closeable={true}
              closeableClick={setRegisterError}
              text={registerError.msg}
              transition={registerError.show}
            />
          )}
        </>
      )}
      <div className="btn-div">
        <button
          className={`log-reg-btn ${theme}`}
          onClick={() => setAlreadyRegistered(!alreadyRegistered.valueOf())}
        >
          {alreadyRegistered ? "Not registered?" : "Already registered?"}
        </button>
        {alreadyRegistered ? (
          <button
            className={`log-pwreset-btn ${theme}`}
            onClick={() => resetPassword()}
          >
            {"Reset password"}
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default LoginRegForm;
