import React, { useContext } from "react";
import CaptchaFormInput from "../CaptchaFormInput/CaptchaFormInput";
import Header from "../Header/Header";

import "./CaptchaForm.css";
import Spinner from "../Spinner/Spinner";
import { ThemeContext } from "../../Generics/ThemeContext/ThemeContext";

function CaptchaForm(props) {
  const { captcha, values, onChange } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {captcha ? (
        <>
          <br />
          <img
            className="cap-img"
            alt="not found"
            width={"80%"}
            src={captcha}
          />
          <div className={`cap-q-span ${theme}`}>
            <span>What kind of room is this?</span>
          </div>
          <CaptchaFormInput
            value={values.answer}
            onChange={onChange}
            name={"answer"}
            type={"text"}
            placeholder={"Captcha Response"}
            errorMessage={
              "Acceptable responses; bedroom, bathroom, hallway, kitchen, livingroom, study"
            }
            pattern={
              "^(Bedroom|Bathroom|Hallway|Kitchen|Livingroom|Study|bedroom|bathroom|hallway|kitchen|livingroom|study)$"
            }
            required={true}
          />
          <div className="cap-btn-div">
            <button className={`cap-button-59 ${theme}`}>Submit</button>
          </div>
        </>
      ) : (
        <>
          <Spinner></Spinner>
        </>
      )}
    </>
  );
}

export default CaptchaForm;
