// external imports
import React, { useContext } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

// component imports
import "./TMFNote.css";
import { ThemeContext } from "../../Generics/ThemeContext/ThemeContext";

function TMFNote(props) {
  const { note, editNote, formEditMode, deleteNote } = props;
  const { theme } = useContext(ThemeContext);

  return note.Note.map((text, index) => {
    return (
      <div key={index} className="note-topNoteContainer">
        <div className="note-indexContainer">
          <h4 className={`note-color ${theme}`}>{index + 1}.</h4>
        </div>
        <div className="note-leftNoteContainer">
          <h4 className={`note-color ${theme}`}>{text}</h4>
        </div>
        <div className="note-rightNoteContainer">
          <BsIcons.BsFillPencilFill
            // color="84b59c"
            onClick={() => {
              editNote({
                key: note.Key[index],
                note: text,
                Note: note.Note,
              });
              formEditMode(true);
            }}
            size={20}
            className="note-editIconColor"
          ></BsIcons.BsFillPencilFill>
          <AiIcons.AiFillCloseCircle
            // color="lightCoral"
            onClick={() => deleteNote(note.Key[index])}
            size={20}
            style={{ marginLeft: "15px" }}
            className="note-deleteIconColor"
          ></AiIcons.AiFillCloseCircle>
        </div>
      </div>
    );
  });
}

export default TMFNote;
