import React, { useEffect, useState, useRef } from "react";

import "./Carousel.css";

function Carousel(props) {
  const { slideArray } = props;
  const [enter, setEnter] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(slideArray[0]);
  const [transitionCSS, setTransitionCSS] = useState("slide-entry");
  const [transitionCSS2, setTransitionCSS2] = useState("slide-exit");
  const transitionRef = useRef("");

  const forwardButton = () => {
    let nextIndex = activeIndex + 1;
    setEnter(!enter);
    if (nextIndex < slideArray.length) {
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(0);
    }
  };

  const backButton = () => {
    let nextIndex = activeIndex - 1;
    if (nextIndex >= 0) {
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(slideArray.length - 1);
    }
  };

  useEffect(() => {
    setActiveItem(slideArray[activeIndex]);
  }, [activeIndex, slideArray]);

  useEffect(() => {
    if (enter) {
      setTransitionCSS("slide-entry");
      setTransitionCSS2("slide-exit");
    } else {
      setTransitionCSS("slide-exit");
      setTransitionCSS2("slide-entry");
    }
  }, [enter]);

  return (
    <>
      {activeItem && (
        <div className={"carousel-wrapper"}>
          <div className={`carousel-div ${transitionCSS2}`}>
            {activeItem.Header}
          </div>
          <div className={`carousel-div ${transitionCSS}`}>
            {activeItem.Header}
          </div>
        </div>
      )}
      <button
        onClick={() => {
          forwardButton();
        }}
      >
        {">"}
      </button>
      <button
        onClick={() => {
          backButton();
        }}
      >
        {"<"}
      </button>
    </>
  );
}

export default Carousel;
