// external imports
import React, { useState, useContext, useEffect } from "react";
import * as BsIcons from "react-icons/bs";

// component imports
import FormButton from "../../Components/Generics/FormButton/FormButton";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
// import "./LoginForm.css";

function ActivateForm(props) {
  const { theme } = useContext(ThemeContext);
  const [values, setValues] = useState({
    uid: "",
    token: "",
  });

  useEffect(() => {
    let parameters = window.location.href.split("activate/")[1];
    let activate_vals = parameters.split("/");
    setValues({ uid: activate_vals[0], token: activate_vals[1] });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.activateUser(values);
  };

  return (
    <form onSubmit={handleSubmit} className={`form ${theme}`}>
      <div className="login-icon">
        <BsIcons.BsKeyFill color="F7A90D" size={130}></BsIcons.BsKeyFill>
      </div>
      <FormButton buttonText="Activate Account"></FormButton>
    </form>
  );
}

export default ActivateForm;
