import React, { useContext, useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";

import FormInput from "../../Generics/FormInput/FormInput";
import TMFInput from "../TMFInput/TMFInput";
import FormButton from "../../Generics/FormButton/FormButton";
import { ThemeContext } from "../../Generics/ThemeContext/ThemeContext";
import "./TMForm.css";

function TMForm(props) {
  const { formEditMode, editNoteInfo, editNote, submitNote } = props;
  const { theme } = useContext(ThemeContext);
  const [values, setValues] = useState({
    Header: "",
    Note: "",
  });

  useEffect(() => {
    if (formEditMode === true) {
      setValues({
        Header: editNoteInfo.Header,
        Note: editNoteInfo.Note,
      });
    }
  }, [editNoteInfo.Note, editNoteInfo.Header, formEditMode]);

  const inputs = [
    {
      id: 1,
      name: "Header",
      type: "text",
      placeholder: "Header",
      // errorMessage: "Note is a mandatory field.",
      // pattern: "^{1,500}$",
      label: "Header",
      required: true,
    },
    {
      id: 2,
      name: "Note",
      type: "text",
      placeholder: "Header Text",
      // errorMessage: "Note is a mandatory field.",
      // pattern: "^[a-zA-Z0-9]{1,500}$",
      label: "Header Text",
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formEditMode === true) {
      editNote(editNoteInfo.key, values);
    } else {
      submitNote(values);
    }
    setValues({ Header: "", Note: "" });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit} className={`tmf-form ${theme}`}>
      <div className="tmf-icon">
        <BsIcons.BsPencilSquare
          color="#f7a90d"
          size={60}
        ></BsIcons.BsPencilSquare>
      </div>
      <FormInput
        key={inputs[0].id}
        {...inputs[0]}
        value={values[inputs[0].name]}
        onChange={onChange}
        lightbackground={"false"}
      />
      <TMFInput
        key={inputs[1].id}
        {...inputs[1]}
        value={values[inputs[1].name]}
        onChange={onChange}
      />
      {/* <div className="btn-div">
        <button className="btn btn-success sub-button">
          {props.formEditMode === true ? "Submit edit" : "Submit"}
        </button>
      </div> */}
      <FormButton buttonText={"add note"}></FormButton>
    </form>
  );
}

export default TMForm;
