import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Generics/ThemeContext/ThemeContext";
import "./CaptchaInfo.css";

export default function CaptchaInfo() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="cap-info-wrap">
      <div className="cap-info-first">
        <a
          className={`link ${theme}`}
          href="https://en.wikipedia.org/wiki/CAPTCHA"
        >
          What is this?
        </a>
      </div>
    </div>
  );
}
