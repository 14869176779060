// external imports
import React, { useState, useContext, useEffect } from "react";
import * as BsIcons from "react-icons/bs";

// component imports
import FormInput from "../../Components/Generics/FormInput/FormInput";
import FormButton from "../../Components/Generics/FormButton/FormButton";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
// import "./LoginForm.css";

function ResetPasswordForm(props) {
  const { theme } = useContext(ThemeContext);
  const [values, setValues] = useState({
    email: "",
  });

  const inputs = [
    {
      id: 4,
      name: "email",
      type: "email",
      placeholder: "email",
      errorMessage: "It should be a valid email address",
      label: "Email",
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.resetPassword(values);
  };

  return (
    <form onSubmit={handleSubmit} className={`form ${theme}`}>
      <div className="login-icon">
        <BsIcons.BsKeyFill color="F7A90D" size={130}></BsIcons.BsKeyFill>
      </div>
      {inputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
          lightbackground={"true"}
        />
      ))}
      <FormButton buttonText="Send Reset Link"></FormButton>
    </form>
  );
}

export default ResetPasswordForm;
