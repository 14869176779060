import React, { useContext } from "react";

import "./FormButton.css";
import { ThemeContext } from "../ThemeContext/ThemeContext";

function FormButton(props) {
  const { buttonText } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <div className="btn-div">
      <button className={`cap-button-59 ${theme}`}>{buttonText}</button>
    </div>
  );
}

export default FormButton;
