import React from "react";
import { Outlet } from "react-router-dom";
import ThemeSwitch from "./ThemeSwitch";

export default () => {
  return (
    <>
      <ThemeSwitch />
      <Outlet />
    </>
  );
};
