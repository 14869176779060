import React from "react";
import Sidebar from "../SideBar/SideBar";
import { Outlet } from "react-router-dom";

export default () => {
  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
};
