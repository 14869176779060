import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import DjangoService from "../../API/DjangoService";
import Carousel from "../../Components/Generics/Carousel/Carousel";
import TMFCarousel from "../../Components/TMFPageComponents/TMFCarousel/TMFCarousel";
import TMForm from "../../Components/TMFPageComponents/TMForm/TMForm";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
import "./NotePage.css";
import Spinner from "../../Components/Captcha/Spinner/Spinner";

function TMF() {
  const [notes, setNotes] = useState([]);
  const [formEditMode, setFormEditMode] = useState(false);
  const [editNoteInfo, setEditNoteInfo] = useState({});
  const { theme } = useContext(ThemeContext);

  let navigate = useNavigate();

  const [DjangoAuth, setDjangoAuth] = useCookies(["DJToken"]);

  //redirect to login if no user token is found
  useEffect(() => {
    if (!DjangoAuth["DJToken"]) {
      navigate("/");
    }
  }, [DjangoAuth, navigate]);

  useEffect(() => {
    DjangoService.GetNotes(DjangoAuth["DJToken"]).then((resp) =>
      setNotes(resp)
    );
  }, []);

  const submitNote = (values) => {
    DjangoService.PostNotes(values, DjangoAuth["DJToken"]).then((resp) => {
      if (200 < resp.status < 299) {
        DjangoService.GetNotes(DjangoAuth["DJToken"]).then((resp) =>
          setNotes(resp)
        );
      }
    });
  };

  const deleteNote = (id) => {
    DjangoService.DeleteNotes(id, DjangoAuth["DJToken"]).then((resp) => {
      if (200 < resp.status < 299) {
        DjangoService.GetNotes(DjangoAuth["DJToken"]).then((resp) =>
          setNotes(resp)
        );
      }
    });
  };

  const editNote = (id, body) => {
    DjangoService.UpdateNotes(id, body, DjangoAuth["DJToken"]).then((resp) => {
      if (200 < resp[1] < 299) {
        DjangoService.GetNotes(DjangoAuth["DJToken"]).then((resp) => {
          setNotes(resp);
          setFormEditMode(false);
        });
      }
    });
  };

  return (
    <div className={`note-overlay ${theme}`}>
      <div className="app">
        <div className="note-carousel">
          <div className={`leftContainer ${theme}`}>
            {notes.length > 0 ? (
              <TMFCarousel
                notes={notes}
                deleteNote={deleteNote}
                editNote={setEditNoteInfo}
                formEditMode={setFormEditMode}
              ></TMFCarousel>
            ) : (
              <Spinner />
            )}
          </div>
          <div className="rightContainer">
            <TMForm
              submitNote={submitNote}
              formEditMode={formEditMode}
              editNote={editNote}
              editNoteInfo={editNoteInfo}
            ></TMForm>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TMF;
