// external imports
import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// component imports
import LoginPage from "./Pages/LoginPage/LoginPage";
import NotePage from "./Pages/NotePage/NotePage";
import WithNav from "./Components/Generics/SideBar/WithNav";
import WithThemeSwitch from "./Components/Generics/ThemeSwitch/WithThemeSwitch";
import WithoutNav from "./Components/Generics/SideBar/WithoutNav";
import Activate from "./Pages/ActivatePage/Activate";
import ResetPassword from "./Pages/ResetPWPage/ResetPassword";
import ResetPasswordConfirm from "./Pages/ResetPWConfirm/ResetPasswordConfirm";
import { ThemeProvider } from "./Components/Generics/ThemeContext/ThemeContext";
import "./App.css";

function App() {
  const [sessionInfo, setSessionInfo] = useState([]);

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route element={<WithThemeSwitch />}>
            <Route element={<WithoutNav />}>
              <Route
                exact
                path="/"
                element={<LoginPage setSessionInfo={setSessionInfo} />}
              />
            </Route>
            <Route element={<WithoutNav />}>
              <Route exact path="/reset-password" element={<ResetPassword />} />
            </Route>
            <Route element={<WithoutNav />}>
              <Route
                exact
                path="/password/reset/confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
            </Route>
            <Route element={<WithoutNav />}>
              <Route
                exact
                path="/activate/:uid/:token"
                element={<Activate />}
              />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path="/note" element={<NotePage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
