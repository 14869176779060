import React from "react";

import "./SplashAnimation.css";

function SplashAnimation() {
  return (
    <>
      {/* <div className={"dot"}></div>
    <div className={"dot"}></div>
    <div className={"dot"}></div> */}
    </>
  );
}

export default SplashAnimation;
