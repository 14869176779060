// external imports
import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// component imports
import DjangoService from "../../API/DjangoService";
import LoginRegForm from "./LoginRegForm";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
import "./LoginPage.css";
import SplashAnimation from "../../Components/LoginComponents/SplashAnimation/SplashAnimation";
import WithCaptcha from "../../Components/Captcha/WithCaptcha";

function LoginPage(props) {
  const [DjangoAuth, setDjangoAuth] = useCookies(["DJToken"]);
  const [loginError, setLoginError] = useState({ show: false, msg: "" });
  const [userCaptchaPassed, setUserCaptchaPassed] = useState(false);
  const { theme } = useContext(ThemeContext);

  let navigate = useNavigate();

  useEffect(() => {
    if (DjangoAuth["DJToken"] !== "null" && !!DjangoAuth["DJToken"]) {
      navigate("/note");
    }
  }, [DjangoAuth]);

  //login user
  const loginUser = (values) => {
    DjangoService.LoginUser(values).then((resp) => {
      if (200 < resp[1] < 299) {
        setDjangoAuth("DJToken", resp[0].access);
      } else {
        setLoginError({
          show: true,
          msg: "A problem occurred while logging in, please try again later",
        });
        setUserCaptchaPassed(false);
      }
    });
  };

  return (
    <div className="overlay">
      <div className={`login-left-container ${theme}`}>
        <SplashAnimation></SplashAnimation>
      </div>
      <div className={`login-right-container ${theme}`}>
        <div className="login">
          <WithCaptcha
            showCaptcha={true}
            userCaptchaPassed={userCaptchaPassed}
            setUserCaptchaPassed={setUserCaptchaPassed}
            childComponent={<LoginRegForm loginUser={loginUser}></LoginRegForm>}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
