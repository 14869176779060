// external imports
import React, { useContext } from "react";

// component imports
import { ThemeContext } from "../ThemeContext/ThemeContext";
import "./Header.css";

function Header(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <h2 className={`color ${theme}`}>{props.text}</h2>
      {props.withLine ? <hr className="line" /> : <div className="spacer" />}
    </>
  );
}

export default Header;
