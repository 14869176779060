import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../ThemeContext/ThemeContext";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import "./ThemeSwitch.css";
function ThemeSwitch() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [switchColor, setSwitchColor] = useState("#F7A90D");
  const [iconTheme, setIconTheme] = useState({
    primary: "#F7A90D",
    secondary: "#f63466",
  });
  const handleClick = () => {
    toggleTheme(theme === "light" ? "dark" : "light");
  };

  const switchHover = () => {
    setSwitchColor(iconTheme.secondary);
  };
  const switchHoverOut = () => {
    setSwitchColor(iconTheme.primary);
  };

  useEffect(() => {
    switch (theme) {
      case "light":
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
        break;
      case "dark":
        setIconTheme({ primary: "#F7A90D", secondary: "#fff" });
        break;
      default:
        setIconTheme({ primary: "#F7A90D", secondary: "#f63466" });
    }
  }, [theme]);

  return (
    <div
      onMouseOver={switchHover}
      onMouseOut={switchHoverOut}
      onClick={handleClick}
    >
      {theme === "light" ? (
        <BsIcons.BsFillMoonFill
          className="theme-switch-button"
          color={switchColor}
        />
      ) : (
        <BiIcons.BiSun className="theme-switch-button" color={switchColor} />
      )}
    </div>
  );
}
export default ThemeSwitch;
