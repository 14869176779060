import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DjangoService from "../../API/DjangoService";
import { ThemeContext } from "../../Components/Generics/ThemeContext/ThemeContext";
import SplashAnimation from "../../Components/LoginComponents/SplashAnimation/SplashAnimation";
import ActivateForm from "./ActivateForm";

function Activate() {
  const { theme } = useContext(ThemeContext);
  const [verified, setVerified] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (verified) {
      navigate("/");
    }
  }, [verified, navigate]);

  //login user
  const activateUser = (values) => {
    DjangoService.ActivateUser(values).then((resp) => {
      if (200 < resp[1] < 299) {
        setVerified(true);
      } else {
        // setLoginError({'show':true, 'msg':'A problem occurred while logging in, please try again later'})
      }
    });
  };

  return (
    <div className="overlay">
      <div className={`login-left-container ${theme}`}>
        <SplashAnimation></SplashAnimation>
      </div>
      <div className={`login-right-container ${theme}`}>
        <div className="login">
          <ActivateForm activateUser={activateUser}></ActivateForm>
          <div className="btn-div">
            <button
              className={`log-reg-btn ${theme}`}
              onClick={() => setVerified(true)}
            >
              {"Back to Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activate;
