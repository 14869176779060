import React from "react";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";

export const toasterData = [
  {
    type: "info",
    icon: <AiIcons.AiOutlineInfoCircle size={20}></AiIcons.AiOutlineInfoCircle>,
    title: "Info",
  },
  {
    type: "error",
    icon: <BiIcons.BiError size={20}></BiIcons.BiError>,
    title: "Error",
  },
  {
    type: "warning",
    icon: <BiIcons.BiErrorCircle size={20}></BiIcons.BiErrorCircle>,
    title: "Warning",
  },
  {
    type: "success",
    icon: <BiIcons.BiCheckCircle size={20}></BiIcons.BiCheckCircle>,
    title: "Success",
  },
];
